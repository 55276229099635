import { onBeforeUnmount, ref, watchEffect, type Ref } from "vue";

/**
 * A composable function that tracks the visibility of an HTML element in the viewport.
 * 
 * @param elementRef - A reactive reference to the HTML element you want to track.
 *                     In Vue 3 + TypeScript, this is typically created using ref() and has a type of Ref<HTMLElement | undefined>.
 *                     The '| undefined' part means the ref might not have an element assigned to it initially.
 * 
 * @returns A reactive reference (Ref<boolean>) that indicates whether the element is currently visible in the viewport.
 *          You can access the current value in your component using '.value', e.g., 'isVisible.value'.
 * 
 * @remarks
 * This function uses the Intersection Observer API to efficiently track element visibility.
 * It's particularly useful for implementing lazy loading, infinite scrolling, or any feature that needs to react to an element becoming visible.
 * 
 * The function handles the following:
 * - Setting up an Intersection Observer to watch the provided element
 * - Updating the isVisible state when the element enters or leaves the viewport
 * - Cleaning up the observer when the component is unmounted to prevent memory leaks
 * 
 * @example
 * ```vue
 * <script setup lang="ts">
 * import { ref } from 'vue'
 * import { useVisibility } from './useVisibility'
 * 
 * const myElement = ref<HTMLElement>()
 * const isMyElementVisible = useVisibility(myElement)
 * 
 * // You can now use isMyElementVisible.value in your template or watch it for changes
 * </script>
 * 
 * <template>
 *   <div ref="myElement">
 *     This element is {{ isMyElementVisible ? 'visible' : 'not visible' }}
 *   </div>
 * </template>
 * ```
 */
export function useVisibility (
  elementRef: Ref<HTMLElement | undefined>,
  options: IntersectionObserverInit = { rootMargin: '0px', threshold: 0.1 }
) {
  const isVisible = ref(false)

  const observer = new IntersectionObserver((entries) => {
    isVisible.value = entries[entries.length - 1].isIntersecting
  }, options)

  watchEffect(() => {
    const element = elementRef.value

    if (element) {
      observer.observe(element)
    } else {
      observer.disconnect()
    }
  })

  onBeforeUnmount(() => {
    observer.disconnect()
  })

  return isVisible
}
